@import "../../variables.scss";

.about-page {
  .page-header {
    position: relative;
    overflow: hidden;
    height: 100vh;
    @media (max-width: 768px) {
      height: 60vh;
    }
    .background {
      height: 100vh;
      @media (max-width: 768px) {
      height: 60vh;
    }
    }
    .side-content {
      width: fit-content;
      display: flex;
      align-items: center;
      font-family: "Avenir Book";
      position: absolute;
      left: 3vw;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
      color: #EC5729;
      top: 0;
      bottom: 0;
      justify-content: center;
      z-index: 99999;
      @media (max-width: 768px) {
        display: none;
      }
      a {
        width: 100%;
        color: #EC5729;
      }
      svg {
        transform: rotate(90deg);
      }
      @media screen and (max-width: 920px) {
        // left: -5%;
      }

      @media screen and (max-width: 600px) {
        // left: -10%;
      }
    }
    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: #fff;
      left: 15%;
      width: 100%;
      height: fit-content;
      @media screen and (max-width: 1000px) {
        left: 10%;
        width: 80%;
      }
      .content-span {
        font-family: "Optima";
        font-weight: 100;
        color: #EC5729;
        font-size: 45px;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          font-size: 22px;
        }
      }
      .content-heading {
        font-family: "Optima";
        font-size: 80px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          font-size: 40px;
        }
      }
      .content-desc {
        font-family: "Avenir";
        font-weight: 300;
        font-size: 24px;
        margin-top: 10px;
        width: 60%;
        @media screen and (max-width: 768px) {
          width: 100%;
          font-size: 20px;
          line-height: 1.2;
        }
        strong {
          font-family: "Avenir";
          font-weight: 800;
        }
      }
    }
    .background {
      pointer-events: none;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 40%;
        height: 2px;
        background: linear-gradient(to right, #EC5729, transparent);
        bottom: 0;
        left: 0;
        opacity: 0.94;
    }
      &:after {
        content: "";
        position: absolute;
        width: 40%;
        height: 2px;
        background: linear-gradient(to left, #EC5729, transparent);
        bottom: 0;
        right: 0;
        opacity: 0.94;
    }
      img {
          object-fit: cover;
          height: 100vh;
          width: 100%;
          @media (max-width: 768px) {
            height: 60vh;
          }
      }
    }
  }
  .intro {
    display: flex;
    color: #fff;
    padding: 0 7vw;
    gap: 80px;
    align-items: center;
    margin-bottom: 30vh;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      row-gap: 30px;
      gap: unset;
      margin-bottom: 20vh;
      padding: 0 5vw;
    }
    .left {
      width: 40%;
      font-family: "Nunito";
      font-weight: 500;
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
      h3 {
        font-size: 36px;
        span {
          font-weight: 300;
        }
      }
    }
    .right {
      width: 60%;
      font-family: "Nunito";
      font-weight: 300;
      font-size: 24px;
      line-height: 1.6;
      @media screen and (max-width: 1000px) {
        width: 100%;
        font-size: 20px;
        text-align: justify;
      }
    }
  }
  .about {
    text-align: center;
    padding: 0 15vw;
    margin-bottom: 20vh;
    margin-top: 15vh;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      row-gap: 30px;
      gap: unset;
      margin-bottom: 20vh;
      padding: 0 5vw;
    }
    .about-span {
      color: #EC5729;
      font-family: "Optima";
      font-weight: 100;
      font-size: 45px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .about-catch {
      font-family: "Optima";
      font-size: 36px;
      color: #fff;
      margin-bottom: 50px;
      font-weight: 300;
      @media (max-width: 768px) {
        font-size: 30px;
      }
      strong {
        font-family: "Optima";
        font-weight: 800;
      }
    }
    .about-desc {
      font-family: "Avenir";
      font-size: 22px;
      line-height: 1.5;
      color: #fff;
      font-weight: 300;
      margin-bottom: 70px;
      letter-spacing: 1.5px;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 20px;
      }
    }
    hr {
      color: red;
    }
  }
  .clients {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20vh;
    @media (max-width: 768px) {
      width: calc(100% - 10vw);
      padding: 0 5vw;
    }
    .clients-span {
      color: #EC5729;
      font-family: "Optima";
      font-size: 45px;
      font-weight: 100;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .clients-catch {
      font-size: 36px;
      color: #fff;
      font-family: "Optima";
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
      strong {
        font-size: 30px;
        font-family: "Optima";
        font-weight: 700;
      }
    }
    .clients-desc {
      color: #9fa4af;
      font-family: "Montserrat";
      font-size: 22px;
      margin-bottom: 80px;
    }
    .clients-swiper {
      overflow-x: hidden;
    }
    .clients-slider {
      padding: 0 10%;
    }
  }
  .milestone {
    margin-bottom: 25vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
      margin-bottom: 20vh;
    }
    @media screen and (max-width: 768px) {
      padding: 0 5vw;
    }
    .milestone-span {
      color: #EC5729;
      font-family: "Optima";
      font-weight: 100;
      font-size: 45px;
      margin-bottom: 20px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .milestone-heading {
      font-family: "Montserrat";
      font-style: italic;
      font-size: 30px;
      color: #fff;
      margin-bottom: 50px;
    }
    .milestones {
      color: #fff;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      //   gap: 20%;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
      }
    }
    .box {
      text-align: center;
      font-family: "Playfair Display";
      width: 33%;
      .number {
        font-size: 80px;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
      .name {
        font-family: "Montserrat";
        font-size: 28px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }

  .mission {
    padding: 60px 10%;
    margin-bottom: 15vh;
    background: #ec57296e;
    border-radius: 0 250px 0 0;
    margin-right: 50px;
    @media screen and (max-width: 1000px) {
      margin-right: 0;
      padding: 60px 10vw 60px 5vw;
      margin-bottom: 10vh;
    }
    .mission-span {
      font-family: "Optima";
      font-weight: 900;
      color: #000000;
      font-size: 55px;
      margin-bottom: 20px;
      letter-spacing: 2.5px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .mission-desc {
      font-family: "Avenir";
      font-weight: 300;
      font-size: 28px;
      color: #fff;
      letter-spacing: -1.44px;
      width: 80%;
      @media screen and (max-width: 768px) {
        // width: 100%;
        font-size: 20px;
        text-align: left;
      }
    }
  }
  .vision {
    text-align: right;
    padding: 60px 10%;
    margin-bottom: 15vh;
    background: rgba(236, 87, 41, 0.431372549);
    border-radius: 0 0 0 250px;
    margin-left: 50px;
    @media screen and (max-width: 1000px) {
      margin-left: 0;
      padding: 60px 10vw 60px 5vw;
      margin-bottom: 10vh;
    }
    .vision-span {
      font-family: "Optima";
      font-weight: 900;
      color: #000000;
      font-size: 55px;
      margin-bottom: 20px;
      letter-spacing: 2.5px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .vision-desc {
      font-family: "Avenir";
      font-weight: 300;
      font-size: 28px;
      color: #fff;
      width: 80%;
      margin-left: auto;
      letter-spacing: -1.44px;
      @media screen and (max-width: 768px) {
        // width: 100%;
        font-size: 20px;
        text-align: right;
      }
    }
  }
  .business-partners {
    text-align: center;
    // padding: 0 20%;
    margin-bottom: 15vh;
    @media (max-width: 768px) {
      padding: 0 5vw;
    }
    .partners-span {
      margin-bottom: 35px;
      font-family: "Optima";
      font-size: 45px;
      color: #EC5729;
      font-weight: 100;
      letter-spacing: 2px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .partners-heading {
      color: #fff;
      font-family: "Avenir";
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 35px;
      @media (max-width: 768px) {
        font-size: 30px;        
      }
      strong {
        font-family: "Avenir";
        font-weight: 800;
      }
    }
    .partners-desc {
      color: #9fa4af;
      font-family: "Montserrat";
      font-size: 22px;
      margin-bottom: 80px;
    }
    .partners-slider {
      padding: 0 10%;
      //   overflow: hidden;
      @media screen and (max-width: 768px) {
        padding: 0 5vw;
      }
    }
    .partners-swiper {
      overflow-x: hidden;
    }
  }
  .founders {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15vh;

    .founders-span {
      margin-bottom: 35px;
      font-family: "Optima";
      font-size: 45px;
      color: #EC5729;
      font-weight: 100;
      letter-spacing: 2px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .founders-heading {
      color: #fff;
      font-family: "Avenir";
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 75px;
      @media (max-width: 768px) {
        font-size: 30px;
        text-align: center;
      }
      strong {
        font-family: "Avenir";
        font-weight: 800;
      }
    }
    .founders-boxes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 50px;
      width: 100%;
      justify-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .founder {
        display: flex;
        gap: 30px;
        align-items: flex-end;
        width: calc( 50% - 30px);
        &:nth-child(odd) {
          flex-direction: row-reverse;
      }
      &:nth-child(even) {
        flex-direction: row;
      }
        @media (max-width: 768px) {
          &:nth-child(even) {
            flex-direction: row-reverse;
            img {
              float: right;
              margin-left: 20px;
            }
          }
          &:nth-child(odd) {
            flex-direction: row;
            img {
              float: left;
              margin-right: 20px;
            }
        }
        }
        @media screen and (max-width: 1000px) {
          flex-direction: column !important;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          display: block;
        }
        .founder-bio {
          p {
            color: #9fa4af;
            font-family: "Montserrat";
            font-weight: 300;
            font-size: 16px;
            line-height: 1.5;
            margin: 3px 0 0 0;
            text-align: justify;
            @media (max-width: 768px) {
              text-align: left;
            }
          }
          strong {
            font-family: "Helvetica";
            font-style: italic;
            color: #EC5729;
            font-size: 20px;
          }
        }
        .founder-img {
          img {
            width: 220px;
            height: 100%;
            // border-radius: 25px;
            @media (max-width: 768px) {
              width: 120px;
            }
          }
        }
      }
    }
  }
}

.business-partners .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  scale: 0.7;
}

.bg1,
.bg2 {
  position: relative;
  > img {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.bg1 {
  > img {
    top: 250px;
  }
}

@media (max-width: 768px) {
  .clients-slider .nav {
    display: none;
  }
}