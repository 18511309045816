@import "../../variables.scss";

.contact-us {
  width: calc(100% - 14vw);
    display: flex;
    flex-direction: column;
    padding: 0 7vw 130px;
    margin: 0 auto 0 auto;
    padding-top: 150px;
    @media (max-width: 768px) {
      padding: 100px 7vw;
    }
  .top {
    color: white;
    margin-bottom: 70px;
    width: 100%;

    .contact-span {
      color: #EC5729;
      font-family: "Optima";
      letter-spacing: 4px;
      font-size: $heading-size-m;
      font-weight: 100;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 30px;
      @media (max-width: 768px) {
        letter-spacing: 2px;
        font-size: 20px;
      }
    }

    .contact-heading {
      font-family: "Montserrat";
      font-style: italic;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }
  }

  .bottom {
    iframe {
      border-radius: 0 0 200px 200px;
      @media (max-width: 768px) {
        border-radius: 0 0 80px 80px;
      }
    }
    form {
      .form-row {
        display: flex;
        margin-bottom: 20px;
        gap: 25px;
      }

      input {
        width: 31.5%;
        background: none;
        padding: 10px 0;
        border: none;
        border-bottom: 2px solid #a1a1a1;
        transition: all 0.2s ease;
        color: #a1a1a1;
        font-family: "Montserrat";
        font-weight: 600;

        &:focus {
          outline: none;
          border-bottom: 2px solid #EC5729;
        }

        &::placeholder {
          font-family: "Montserrat";
          color: #a1a1a1;
        }

        &:-internal-autofill-selected {
          background-color: #000 !important;
          background: none !important;
          color: #a1a1a1 !important;
          font-family: "Montserrat";
          font-weight: 600;
          appearance: none !important;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        font-family: "Montserrat";
        font-weight: 600;
        color: #a1a1a1;
        transition: all 99999s ease-in-out 0s;
      }

      textarea {
        width: 100%;
        resize: none;
        background: none;
        border: none;
        border-bottom: 2px solid #a1a1a1;
        transition: all 0.2s ease;
        color: #a1a1a1;
        font-family: "Montserrat";
        font-weight: 600;

        &:focus {
          outline: none;
          border-bottom: 2px solid #EC5729;
        }

        &::placeholder {
          font-family: "Montserrat";
          color: #a1a1a1;
        }
      }
      .center-readmore {
        margin-top: 2px;
        font-size: 11px;
      }
      button {
        background: none;
        border: 1px solid #EC5729;
        color: #EC5729;
        padding: 12px 40px;
        font-family: "Montserrat";
        font-weight: 600;
        border-radius: 15px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .top {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .bottom {
      form {
        .form-row {
          flex-direction: column;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
