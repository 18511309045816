@import "../../variables.scss";

.separator {
  display: flex;
  justify-content: space-evenly;
  column-gap: 2vw;
  // background: #000000;
  height: fit-content;
  align-items: center;
  width: 100%;
  padding: 40px 7vw;
  position: relative;
  margin-top: auto;
  
  @media screen and (max-width: 700px) {
    padding: 40px 7vw 0;
    flex-wrap: wrap;
    // margin: 20px 0;
    flex-direction: column;
    row-gap: 20px;
  }

  // @media only screen and (max-width: 600px) {
  //   width: 100%;
  // }

  .service {
    display: flex;
    align-items: center;
    column-gap: 1vw;
    height: fit-content;
    border-bottom: 1px solid #d9e6ec;
    padding-bottom: 15px;

    @media screen and (max-width: 700px) {
      width: 100%;
      border-bottom: none;
      justify-content: space-between;
      column-gap: 5vw;
    }

    .content {
      color: #d9e6ec;
      font-family: "Montserrat";

      h3 {
        font-size: 20px;
        margin: 0;
        font-family: "Montserrat";
        font-weight: 500;
        color: #EC5729;
        letter-spacing: 1px;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
        // @media screen and (max-width: 550px) {
        //   font-size: 4vw;
        // }
      }

      p {
        color: #d9e6ec;
        font-weight: 400;
        letter-spacing: 1.2px;
        font-size: 12px;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
        // @media screen and (max-width: 550px) {
        //   font-size: 2.7vw;
        // }
      }
    }
  }
  .icon {
    svg {
      @media (max-width: 768px) {
        width: 35px;
        height: 35px;
      }
    }
  }
}
