@import "../../variables.scss";

.our-services {
  padding: 0 0 0 7vw;
  font-family: "Montserrat";
  width: calc(90% - 7vw);
  overflow: hidden;
  margin-bottom: 150px;
  padding-top: 50px;
  margin-top: 80px;
  @media screen and (max-width: 768px) {
    padding: 0 32.5px;
    // width: calc(100% - 10vw);
    margin-bottom: 50px;
  }
  h3 {
    text-transform: uppercase;
    color: #EC5729;
    margin: 0;
    font-weight: 100;
    font-size: $heading-size-m;
    margin-bottom: 60px;
    font-family: "Optima";
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, transparent, #EC5729);
      z-index: 999;
    }
    @media screen and (max-width: 768px) {
      font-weight: 500;
      text-transform: uppercase;
      color: #EC5729;
      font-size: 16px;
      margin: 0;
      margin-bottom: 40px;
      font-size: 20px;
    }
  }
  strong {
    color: #ffffff;
    font-size: 28px;
    font-weight: 300;
    width: 85%;
    font-family: "Avenir";
    line-height: 45px;
    @media screen and (max-width: 768px) {
      color: #ffffff;
      font-size: 18px;
      line-height: 35px;
      font-weight: 300;
    }
    span {
      font-weight: 800;
    }
  }
  p.regular {
    color: #9fa4af;
    font-weight: 300;
    line-height: 1.7;
    font-size: 16px;
    width: 100%;
    margin: 15px 0 40px 0;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin: 0;
      width: 100%;
    }
  }
}
