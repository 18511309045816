@import "../../variables.scss";

.multi-slider {
  position: relative;
  margin-top: 100px;
  .swiper-slide:nth-child(even) {
    .service-no,
    .service-head,
    .service-cta {
      color: #EC5729 !important;
    }
    .service-no::before {
      background: #EC5729 !important;
    }
  }
  .slide {
    display: flex;

    .index {
      width: 30%;
      .service-no {
        width: fit-content;
        position: relative;
        transform: rotate(270deg);
        color: #707070;
        font-family: "Nunito";
        font-weight: 700;
        font-size: 40px;
        &::before {
          position: absolute;
          top: 50%;
          right: 120%;
          content: "";
          width: 55px;
          height: 1px;
          background-color: #707070;
        }
      }
    }
    .content {
      font-family: "Montserrat";
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
      }
      .service-head {
        color: white;
        font-size: 1.7vw;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      .service-text {
        color: #9fa4af;
        font-weight: 300;
        min-height: 80px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 1.7;
        }
      }
      .seemore {
        a {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
        }
        
      }
    }
  }
  .nav {
    position: absolute;
    top: -60px;
    right: 0;
    width: 100%;
    left: 90%;
    @media screen and (max-width: 500px) {
      left: 85%;
    }
    // display: none;
    .swiper-button-next {
      inset: 0;
      left: 50px;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
    .swiper-button-prev {
      inset: 0;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
  }
}

.swiper-slide:nth-child(even) .seemore a {
  color: #EC5729;
}

.swiper-slide:nth-child(odd) .seemore a {
  color: #9FA4AF;
}