@import "../../variables.scss";

.navbar {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  justify-content: space-between;
  background: #00000078;
  padding: 0px 20px;
  position: absolute;
  top: 0;
  z-index: 2;
  width: calc(100% - 40px);
  overflow: hidden;
  .left {
    color: #fff;
    margin-left: 5vw;
    a {
      display: flex;
    }
  }
  .middle {
    display: flex;
    column-gap: 40px;
    margin-right: 7vw;
    .active:before {
      content: " ";
      width: 100%;
      height: 5%;
      background: #EC5729;
      position: absolute;
      top: 0;
      left: 0;
  }
    .nav-link {
      font-family: "Avenir";
      text-decoration: none;
      color: #fff;
      transition: all 0.3s ease;
      padding: 20px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      position: relative;
    }
    .nav-link:hover {
      color: #fe8033;
    }
  }
  .right {
    // display: flex;
    gap: 15px;
    position: absolute;
    right: 4vw;
    display: none;
    a {
      color: #fff;
      svg {
        padding: 3px;
        background: white;
        border-radius: 50%;
      }
    }
  }
}
