@import "../../variables.scss";

.team-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 100px;
  position: relative;
  width: 100%;
  padding: 70px 0;
  @media (max-width: 768px) {
    width: calc( 100% - 65px );
  }
  .sec-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.1;
    @media (max-width: 768px) {
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 32.5px;
  }
  .sec-content {
    h2 {
      color: #EC5729;
      font-family: "Optima";
      letter-spacing: 4px;
      font-size: 55px;
      font-weight: 100;
      margin-bottom: 15px;
      text-align: center;
      @media (max-width: 768px) {
        letter-spacing: 2px;
        font-size: 20px;
      }
    }
    .subtitle {
      color: white;
      font-family: 'Avenir';
      font-size: 26px;
      width: 60%;
      text-align: center;
      margin: 0 auto;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 18px;
        line-height: 35px;
      }
      span {
        font-weight: 600;
      }
    }
    p {
      color: #9FA4AF;
      text-align: center;
      font-size: 14px;
      padding: 25px 0 70px 0;
      width: 60%;
      margin: 0 auto;
      font-family: "Montserrat";
      line-height: 1.7;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
      }
    }
    .team-photos {
      display: flex;
      justify-content: center;
      width: 100%;
      column-gap: 30px;
      @media (max-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 30px;
      }
      img {
        max-width: 180px;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
      div {
        position: relative;
        @media (max-width: 768px) {
          width: 45%;
        }
        .personalinfo {
          position: absolute;
          bottom: 0;
          background: linear-gradient(to top, #000000, transparent 60%);
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          justify-content: flex-end;
          align-items: center;
          @media (max-width: 768px) {
            width: 90%;
          }
          h3 {
            font-family: "optima";
            font-weight: 700;
            color: #EC5729;
            margin: 0;
            @media (max-width: 768px) {
              font-size: 14px;
            }
            }
            h4 {
            font-family: "optima";
            color: white;
            font-weight: 100;
            margin: 0;
            margin-bottom: 30px;
            font-size: 14px;
            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
