@import "../../variables.scss";

.clients-section {
  padding: 0 0 0 7vw;
  font-family: "Montserrat";
  width: calc(90% - 7vw);
  overflow: hidden;
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding: 0 32.5px;
  }
  h3 {
    text-transform: uppercase;
    color: #EC5729;
    margin: 0;
    margin-bottom: 60px;
    font-family: "Optima";
    // letter-spacing: 4px;
    font-size: 55px;
    font-weight: 100;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 60%;
      height: 2px;
      background: linear-gradient(to right, #EC5729, transparent);
      left: 0;
      bottom: -25px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
      font-size: 20px;
      margin-top: 10px;
    }
  }
  strong {
    color: #ffffff;
    font-size: 28px;
    font-weight: 300;
    width: 85%;
    font-family: "Avenir";
    line-height: 45px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 35px;
    }
    span {
      font-weight: 800;
    }
  }
  p {
    color: #9fa4af;
    font-weight: 300;
    line-height: 1.7;
    font-size: 16px;
    width: 80%;
    margin: 15px 0 80px 0;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 14px;
    }
  }
}
