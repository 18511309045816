@import "../../variables.scss";

.services-page {
  .page-header {
    position: relative;
    overflow-x: hidden;
    .side-content {
      width: fit-content;
      display: flex;
      align-items: center;
      font-family: "Avenir Book";
      position: absolute;
      left: 3vw;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
      color: #EC5729;
      top: 0;
      bottom: 0;
      justify-content: center;
      z-index: 99999;
      a {
        width: 100%;
        color: #EC5729;
      }
      svg {
        transform: rotate(90deg);
      }
      @media screen and (max-width: 920px) {
        // left: -5%;
      }

      @media screen and (max-width: 600px) {
        // left: -10%;
      }
    }
    .content {
      position: absolute;
      top: 30%;
      color: #fff;
      left: 15%;
      width: 50%;
      @media screen and (max-width: 1000px) {
        left: 10%;
        width: 80%;
      }
      .content-span {
        font-family: "Optima";
        font-weight: 100;
        text-transform: uppercase;
        color: #EC5729;
        font-size: 55px;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .content-heading {
        font-family: "Montserrat";
        font-size: 50px;
        font-weight: 900;
        @media screen and (max-width: 768px) {
          font-size: 40px;
        }
      }
      .content-desc {
        font-family: "Avenir";
        font-weight: 300;
        font-size: 24px;
        margin-top: 10px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 2;
        }
        strong {
          font-family: "Avenir";
          font-weight: 800;
        }
      }
    }
    .background {
      pointer-events: none;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
  .intro {
    display: flex;
    color: #fff;
    padding: 0 7vw;
    gap: 80px;
    align-items: center;
    margin-bottom: 30vh;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      row-gap: 30px;
      gap: unset;
      margin-bottom: 20vh;
      padding: 0 5vw;
    }
    .left {
      width: 40%;
      font-family: "Nunito";
      font-weight: 500;
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
      h3 {
        font-size: 36px;
        span {
          font-weight: 300;
        }
      }
    }
    .right {
      width: 60%;
      font-family: "Nunito";
      font-weight: 300;
      font-size: 24px;
      line-height: 1.6;
      @media screen and (max-width: 1000px) {
        width: 100%;
        font-size: 20px;
        text-align: justify;
      }
    }
  }
  .about {
    text-align: center;
    padding: 0 15vw;
    margin-bottom: 20vh;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      row-gap: 30px;
      gap: unset;
      margin-bottom: 20vh;
      padding: 0 5vw;
    }
    .about-span {
      color: #EC5729;
      font-family: "Optima";
      font-weight: 100;
      font-size: 55px;
      margin-bottom: 20px;
    }
    .about-catch {
      font-family: "Avenir";
      font-size: 36px;
      color: #fff;
      margin-bottom: 50px;
      font-weight: 300;
      strong {
        font-family: "Avenir";
        font-weight: 800;
      }
    }
    .about-desc {
      font-family: "Avenir";
      font-size: 20px;
      width: 55%;
      margin: 0 auto;
      line-height: 1.6;
      color: #9fa4af;
      font-weight: 300;
      margin-bottom: 70px;
      text-align: center;
      letter-spacing: 1.5px;
      @media screen and (max-width: 1000px) {
        text-align: justify;
        font-size: 20px;
        width: 100%;
      }
    }
    .services {
      display: flex;
      flex-direction: column;
      gap: 70px;
      .service-row {
        display: flex;
        align-items: center;
        gap: 50px;
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
        &:nth-child(even) {
          //   background: red;
          .service-separator {
            background: #EC5729 !important;
          }
          .service-head {
            color: #EC5729;
          }
        }
        .service-col {
          background: #0d0d0d;
          color: white;
          padding: 35px 45px;
          border-radius: 57px;
          .service-head {
            font-size: 25px;
            font-weight: 500;
            font-family: "Montserrat";
            margin-bottom: 15px;
          }
          .service-body {
            color: #9fa4af;
            font-weight: 300;
            font-family: "Montserrat";
            line-height: 2;
          }
        }
        .service-separator {
          width: 1px;
          height: 60px;
          background: #707070;
          @media screen and (max-width: 900px) {
            height: 30px;
          }
        }
      }
    }
  }
}


// @media (max-height: 720px) {
//   .services-page .page-header .content .content-span,
//   .services-page .about .about-span,
//   .portfolio-section .top .content .portfolio-span,
//   #ourpackages .top .content .packages-span {
//     font-size: 30px !important;
//   }
// }