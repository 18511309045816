@import "../../variables.scss";

#ourpackages {
  .top {
    padding: 0 0 0 7vw;
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    .content {
      width: 50%;
      color: #fff;
      font-family: "Montserrat";
      @media screen and (max-width: 768px) {
        width: 80%;
      }

      .packages-span {
        font-family: "Optima";
        font-weight: 100;
        text-transform: uppercase;
        color: #EC5729;
        font-size: 55px;
        margin: 0;
        padding-bottom: 10px;
        // padding-bottom: 15px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .packages-heading {
        font-size: 23px;
        font-family: "Avenir";
        font-weight: 300;
        @media screen and (max-width: 768px) {
          font-size: 21px;
        }

        strong {
          font-family: "Avenir";
          font-weight: 800;
        }
      }
      .packages-paragraph {
        color: #9fa4af;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    column-gap: 30px;
    width: 70%;
    margin: 0 auto;
    @media screen and (max-width: 1350px) {
      width: 90%;
      flex-wrap: wrap;
      row-gap: 30px;
    }

    .package,
    .benefits {
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .benefits {
      row-gap: 15px;
      p {
        margin: 0;
        font-size: 14px;
      }
    }
    .package {
      background: #060606;
      padding: 8px 20px 40px 20px;
      width: calc(100% / 3);
      border-radius: 30px;
      @media screen and (max-width: 1350px) {
        width: 40%;
      }
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      h3 {
        font-size: 22px;
        margin-bottom: 25%;
      }
      .cta {
        color: #ffffff;
        padding: 10px 20px;
        font-size: 14px;
        border-radius: 25px;
        border: 1px solid white;
        margin-top: 30px;
      }
    }
    .package.standard {
      background: #000000 !important;
      filter: drop-shadow(0 0 8px #ccccdf40);
      h3 {
        color: #EC5729;
      }

      .cta {
        background: #f6663f;
        border: unset;
      }
    }
    .package.basic,
    .package.pro {
      filter: drop-shadow(0 0 4px #ccccdf20);
      // display: none;
    }
  }
}
