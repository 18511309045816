@import "../../variables.scss";

.coming-soon {
  @media only screen and (min-width: 800px) {
    h1 {
      font-size: 6em;
    }
    p {
      font-size: 1.6em;
    }
  }

  @media only screen and (max-width: 320px) {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1.2em;
    }
  }
  h1 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 4em;
    color: #333;
    -webkit-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6),
      0 0 2px rgba(0, 0, 0, 0.7);
    -moz-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
    word-spacing: 16px;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    color: #222;
    // text-shadow: 0 0 40px #ffffff, 0 0 30px #ffffff, 0 0 20px #ffffff;
  }
}
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  // background: url("");
  background-size: cover;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: table;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.item {
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
}
