@import "../../variables.scss";

.portfolio-section {
  margin-bottom: 10vh;

  @media screen and (max-width: 880px) {
    .top {
      flex-direction: column;
      padding: 0 7vw !important;
      @media screen and (max-width: 768px) {
        padding: 0 !important;
        text-align: center;
      }
      .content {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .tabs {
        font-size: 14px;
      }
    }
  }

  .top {
    padding: 0 0 0 7vw;
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    .content {
      width: 50%;
      color: #fff;
      font-family: "Montserrat";

      .portfolio-span {
        font-family: "Optima";
        font-weight: 100;
        text-transform: uppercase;
        color: #EC5729;
        font-size: 55px;
        margin: 0;
        padding-bottom: 10px;
        // padding-bottom: 15px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .portfolio-heading {
        font-size: 23px;
        font-family: "Avenir";
        font-weight: 300;
        @media screen and (max-width: 768px) {
          font-size: 21px;
        }

        strong {
          font-family: "Avenir";
          font-weight: 800;
        }
      }
    }

    .tabs {
      display: flex;
      gap: 30px;
      justify-content: center;
      align-items: center;
      color: white;
      width: 60%;
      display: none;

      .tab {
        color: #9fa4af;
        font-family: "Avenir";
        font-weight: 900;
        cursor: pointer;
        font-size: 12px;

        &.active {
          color: white;
        }
      }
    }
  }
  .bottom {
    .portfolio-swiper {
      position: relative;
      .nav {
        position: absolute;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        width: 100%;
        .swiper-button-next {
          right: 5%;
        }
        .swiper-button-prev {
          left: 5%;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          color: white;
        }
      }
    }
  }
}
