@import "../../variables.scss";

.contact-page {
  .page-header {
    position: relative;
    overflow-x: hidden;
    .content {
      position: absolute;
      top: 30%;
      color: #fff;
      left: 15%;
      width: 50%;
      @media screen and (max-width: 768px) {
        left: 10%;
        width: 80%;
      }
      .content-span {
        font-family: "Optima";
        font-weight: 100;
        font-size: 55px;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .content-heading {
        font-family: "Montserrat";
        font-size: 80px;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 50px;
        }
      }
      .content-desc {
        font-family: "Avenir";
        font-weight: 400;
        font-size: 24px;
        margin-top: 10px;
        white-space: nowrap;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 2;
        }
        strong {
          font-weight: 900;
          font-family: "Avenir";
        }
      }
    }
    .background {
      pointer-events: none;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
  .contact-info {
    color: #fff;
    padding: 60px 15%;
    margin-bottom: 50px;
    .top {
      margin-bottom: 50px;
      .heading {
        font-family: "Optima";
        font-size: 55px;
        font-weight: 100;
        margin-bottom: 20px;
        strong {
          font-weight: 700;
          font-family: "Optima";
        }
      }
      .desc {
        font-family: "Montserrat";
        font-weight: 300;
        color: #9fa4af;
        font-size: 16px;
        width: 50%;
        // margin-bottom: 30px;
        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
    .bottom {
      display: flex;
      gap: 50px;
      justify-content: space-between;
      @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
      }
      .box {
        display: flex;
        gap: 20px;
        .icon {
          color: #EC5729;
          svg {
            width: 2.2em;
            height: 2.2em;
          }
        }
        .text {
          font-family: "Nunito";
          .title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .info {
            font-family: "Avenir";
            font-weight: 400;
          }
        }
      }
    }
  }
}
