
    // Font Sizes
    // ===========================
    // HERO FONT
    // $hero-size-xs:
    // $hero-size-s:
    // $hero-size-m:
    // $hero-size-lg:
    // $hero-size-xlg:

    // HEADING FONT
    $heading-size-xs: 20px;
    $heading-size-s: 30px;
    $heading-size-m: 55px; 
    $heading-size-lg: 70px;
    $heading-size-xlg: 80px;
    
    // PARAGRAPH FONT
    $paragraph-size-xs: 12px;
    $paragraph-size-s: 14px;
    $paragraph-size-m: 16px;
    $paragraph-size-lg: 18px;
    $paragraph-size-xlg: 20px;