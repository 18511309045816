@import url("https://unpkg.com/@videojs/themes@1/dist/forest/index.css");
@import "../../variables.scss";

.video-js {
  width: 100vw;
  height: 100vh;

  .vjs-big-play-button {
    width: 3em;
    height: 3em;
    // display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    top: 40%;
    z-index: 1;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E") !important;

    .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .vjs-control-bar {
    display: none;
  }
}

.vjs-has-started .vjs-big-play-button {
  display: block;
}

.vjs-playing .vjs-big-play-button {
  display: none;
}

.video-js .vjs-tech {
  width: unset;
}

.player-wrapper {
  position: relative;

  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;

    .content-span {
      color: #EC5729;
      font-size: 16px;
      font-family: "Avenir";
      font-weight: 900;
    }

    .content-heading {
      font-family: "Montserrat";
      font-size: 60px;
      color: #fff;
    }

    .content-catch {
      font-family: "Montserrat";
      font-weight: 300;
      color: #EC5729;
      font-size: 40px;
    }
  }

  @media screen and (max-width: 900px) {
    .video-js button {
      background-size: 2em 2em !important;
    }

    .video-js .vjs-big-play-button {
      width: 2em;
      height: 2em;
    }

    .overlay-content {
      .content-heading {
        font-size: 30px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .overlay-content {
      .content-catch {
        font-size: 24px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    .overlay-content {
      .content-heading {
        font-size: 20px;
      }
      .content-catch {
        font-size: 18px;
      }
      .content-span {
        font-size: 14px;
      }
    }
  }
}
