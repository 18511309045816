@import "../../variables.scss";

.wrap-p {
  // background: url("../../../public/bgslider.jpg");
  .swiper-slide {
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: max(85vh, 700px);
      // transform: rotate(45deg);
    }
  }
}
