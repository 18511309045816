@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "../../variables.scss";

#footer {
  font-family: "Open Sans";
    position: fixed;
    top: 0;
    z-index: 0;
    height: 100vh;
    width: 100%;
    @media (max-width: 768px) {
      top: unset;
      bottom: 0;
    }
    .footer-contents {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

  @media screen and (max-width: 768px) {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    color: black;
    font-weight: 600;
    font-size: 16px;
    // text-transform: uppercase !important;

    @media screen and (max-width: 768px) {
      font-size: 21px;
      text-align: center;
    }
  }

  .content {
    padding: 0 7vw;
    display: flex;
    column-gap: 20px;
    background: #EC5729;
    width: calc(100% - 14vw);
    flex-wrap: wrap;
    height: 90vh;
    align-content: flex-end;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 5vw;
    }

    .left {
      width: 55%;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      img {
        height: 55px !important;
      }

      .footerlinks {
        display: flex;
        column-gap: 6vw;
        margin-top: 10px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        h3 {
          color: #000000;
          font-weight: 600;
          font-size: 16px;
        }

        .column,
        .innercolumn {
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 768px) {
            align-items: center;
          }

          a {
            color: #000000;
            font-weight: 500;
            font-size: 12px;
            line-height: 2vw;

            @media screen and (max-width: 768px) {
              font-weight: 100;
              font-size: 14px;
              line-height: 32px;
            }
          }

          .innercols {
            display: flex;
            flex-direction: row;
            column-gap: 30px;

            @media screen and (max-width: 768px) {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    .right {
      width: 40%;
      display: flex;
      flex-direction: column;
      row-gap: 7%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .ourstats {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          align-items: center;
          padding: 30px 0;
          row-gap: 10px;
        }
      }

      div {
        color: black;
        font-size: 15px;
        font-weight: 700;
        margin-top: 10px;

        @media screen and (max-width: 768px) {
          font-size: 15px;
        }

        strong {
          color: white;
          font-size: 1.3vw;
          text-transform: uppercase !important;

          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
      }
    }

    .newslettersection {

      // margin-top: 40px;
      .newsletter-subscribe {
        width: 100%;
        display: flex;
        flex-direction: row;

        input:nth-child(1) {
          padding: 10px 15px;
          width: 65%;
          border-radius: 25px 0 0 25px;
          border: 0;

          @media screen and (max-width: 768px) {
            padding: 10px;
          }

          &::placeholder {
            font-family: "Open Sans";
          }
        }

        a {
          font-size: 14px;
          padding: 10px 15px;
          width: 35%;
          // border-top-right-radius: 25px;
          // border-bottom-right-radius: 25px;
          border: 0;
          font-family: "Open Sans";
          cursor: pointer;
          background: black;
          text-align: center;
          text-transform: uppercase;
          font-weight: 400;
          color: white;
          border-radius: 0 25px 25px 0;
          -webkit-border-radius: 0 25px 25px 0;
          -moz-border-radius: 0 25px 25px 0;
          border-radius: 0 25px 25px 0;
          -khtml-border-radius: 0 25px 25px 0;

          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }

        input:focus-visible {
          outline: 0;
        }
      }
    }
  }

  .bottom-border {
    border: 1px solid black;
    width: 100%;
    margin-top: 50px;
  }
}

.email-section {
  color: black;
  font-size: 12px;
  font-family: "Avenir";
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-weight: 100;
    font-size: 12px;
    text-align: center;
  }
}

.captial {
  text-transform: uppercase !important;
}

#socket {
  // width: 100%;
  background: #ec5729;
  display: flex;
  flex-direction: row;
  padding: 2vh 7vw 4vh;
  row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .left {
    row-gap: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      align-items: center;
    }

    .quicklinks {
      display: flex;
      column-gap: 10px;

      @media screen and (max-width: 768px) {
        flex-direction: row;
        column-gap: 10px;
      }

      a {
        color: black;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Open Sans';

        @media screen and (max-width: 768px) {
          font-weight: 100;
          font-size: 12px;
        }
      }
    }

    .copyrights {
      color: black;
      font-weight: 400;
      font-size: 0.8vw;

      @media screen and (max-width: 768px) {
        font-weight: 300;
        font-size: 13px;
      }

      a {
        color: black;
      }
    }
  }
}

.right {
  .social-icons {
    display: flex;
    column-gap: 5px;

    a {
      svg {
        width: 14px;
        height: 14px;
        fill: black;
        background: white;
        padding: 5px;
        border-radius: 50%;
      }
    }
  }
}

.home-whole-wrapper,
.services-page,
.about-page,
.contact-page {
  position: relative;
  z-index: 1;
  background: black;
  margin-bottom: 80vh;
  border-radius: 0 0 180px 180px;
  @media (max-width: 768px) {
    border-radius: 0 0 80px 80px;
  }
}

@media (min-height: 715px) {
  #footer .content .right div {
        font-size: 18px;
        strong {
              font-size: 22px;
        }
  }
  #footer .content .left img {
    height: 75px !important;
}
#footer .content .left .footerlinks h3 {
  font-size: 22px;
}
#footer .content .left .footerlinks .column a, 
#footer .content .left .footerlinks .innercolumn a {
  font-size: 18px;
  font-weight: 400;
}
#footer h3 {
  font-size: 22px;
}
.email-section {
  font-size: 18px;
  font-weight: 400;
}
#socket .left .quicklinks a {
  font-size: 18px;
  font-weight: 400;
}
#socket .left .copyrights {
  font-size: 18px;
}
}


@media (min-height: 750px) {
  .home-whole-wrapper, 
  .services-page, 
  .about-page, 
  .contact-page {
    margin-bottom: 70vh;
}
}

