@import "../../variables.scss";

.home-hero-section {
    background: #000000;
    height: 60vh;
    position: relative;
    padding: 7vh 0 20vh 10vw;
    .side-content {
    width: fit-content;
    display: flex;
    align-items: center;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 14px;
    position: absolute;
    left: 3vw;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    color: #EC5729;
    top: 100px;
    bottom: 0;
    justify-content: center;
    z-index: 99999;
    @media screen and (max-width: 768px) {
        display: none;
    }
      a {
        width: 100%;
        color: #EC5729;
      }
        svg {
            transform: rotate(90deg);
            width: 18px;
            fill: #EC5729;
            // margin-bottom: 5px;
        }
    }
    @media (max-width: 768px) {
        padding: 7vh 7vw 60px;
        height: 40vh;
    }
    @media (min-width: 1500px) {
        padding: 7vh 7vw 60px;
        height: 50vh;
    }
    h1 {
        color: white;
        width: 20%;
        font-family: "Optima";
        letter-spacing: 2px;
        line-height: 1;
        text-transform: uppercase;
        font-size: 80px;
        font-weight: 100;
        margin: 100px 0 0px;
        @media (max-width: 768px) {
            font-size: 30px;
            width: 100%;
            margin: 70px 0 0px;
        }
        @media (min-width: 1500px) {
            font-size: 100px;
        }
    }
    .bottom-part {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        right: 0;
        .top {
            width: 30%;
            color: white;
            margin-left: auto;
            padding-right: 7vw;
            font-family: 'Avenir';
            font-weight: 100;
            text-align: right;
            font-size: 24px;
            margin-bottom: 0;
            // margin-right: 7vw;
        @media (max-width: 768px) {
            width: 93%;
            color: white;
            margin-right: auto;
            font-weight: 100;
            text-align: left;
            font-size: 16px;
            margin: 30px 0;
            padding-inline: 7vw;
        }
        }
        .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-left: 0;
            @media screen and (max-width: 768px) {
                padding-inline: 2vw;
            }
            .cta {
                color: white;
                border: 1px solid #EC5729;
                padding: 7px 18px;
                border-radius: 25px;
                display: block;
                font-size: 14px;
                font-family: "Montserrat";
                font-weight: 200;
                @media (max-width: 768px) {
                padding: 13px 5px;
                border-radius: 25px;
                font-size: 14px;
                width: 100%;
                text-align: center;
                }
            }
            .sm-icons {
                margin: 0 15px;
                display: flex;
                flex-direction: row;
                column-gap: 7px;
                svg {
                    width: 14px;
                    height: 14px;
                    fill: white;
                    background: #EC5729;
                    padding: 5px;
                    border-radius: 50%;
                }
            }
            .line {
                width: 65%;
                height: 0.6px;
                background: #EC5729;
            }
        }
    }
}

