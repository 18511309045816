@import "../../variables.scss";

.countries {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Montserrat";
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin-bottom: 10vh;
  padding-bottom: 100px;

  .countries-span {
    color: #EC5729;
    font-size: 45px;
    font-weight: 100;
    font-family: "Optima";
    @media screen and (max-width: 768px) {
      font-size: 19px;
    }
  }

  .countries-desc {
    color: #9fa4af;
    width: 30%;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 13px;
      width: 70%;
    }
  }

  .countries-social {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 25px;
    a {
      color: white;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .countries-img img {
    width: 90%;
  }
}
