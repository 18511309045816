@import "../../variables.scss";

.clients-slider {
  position: relative;
  .leftt {
    width: 155px;
    height: 124px;
    margin-right: 90px;
  }
  .nav {
    position: absolute;
    top: -60px;
    right: 0;
    width: 100%;
    left: 90%;
    @media screen and (max-width: 500px) {
      left: 85%;
    }
    // display: none;
    .swiper-button-next {
      inset: 0;
      left: 50px;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
    .swiper-button-prev {
      inset: 0;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
  }
  .clients-swiper {
    overflow: hidden;
  }
}
