@import "../../variables.scss";

// $heading-size-lg: 70px;

.line-anim-wrapper {
  // overflow-x: hidden !important;
  // width: 100%;
  .pin-spacer-line {
    margin-bottom: 0 !important;
  }
  .blackbg {
    //   width: 100%;
    background: #292929;

    .herosection {
      height: 100vh;
      padding: 0 20px;
      width: fit-content;
      display: flex;
      align-items: center;

      .sectioncontent {
        position: relative;

        .sectionbg {
          position: absolute;
          top: 0;
          left: -3vw;
          color: #434343;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          pointer-events: none;

          strong:nth-child(1) {
            letter-spacing: 1.5vw;
            font-size: 15vw;
            font-weight: 500;
            color: #434343;
            // -webkit-text-stroke: 3px #fe80332f;
          }

          strong:nth-child(2) {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            color: #fe8033;
            font-size: 3vw;
            letter-spacing: 0.2vw;
          }
        }
      }

      h1 {
        color: #bcbcbc;
        text-transform: uppercase;
        font-size: 4vw;
        font-weight: 500;
        margin: 12vh 0 10px 0;
        position: relative;
        z-index: 1;
      }

      p {
        width: 65%;
        text-align: justify;
        position: relative;
        z-index: 1;
        margin-top: 0;
        color: white;
      }

      .buttons {
        margin-top: 10vh;
        display: flex;
        column-gap: 20px;
        width: 65%;
        position: relative;
        z-index: 1;

        a {
          color: white;
          border-radius: 50px;
          padding: 15px;
        }

        a:nth-child(1) {
          font-weight: 300;
          border: 1px solid #fe8033;
          width: 40%;
          text-align: center;
        }

        a:nth-child(2) {
          font-weight: 500;
          background: #fe8033;
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  .aboutus {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    margin-right: 0 !important;
    height: 110vh !important;
    // padding: 0 0 0 7vw;
    margin-top: 50px;
    margin-bottom: 100px;
    background: #000000;
    position: relative;
    overflow: hidden;
    @media (max-width: 1600px) {
      height: calc(130vh + 100px) !important;
    }
    .sec-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      align-content: space-around;
      // margin-top: 100px;
      @media (max-width: 1600px) {
        margin-top: 100px;
      }
    }
    .about-bg {
      position: absolute;
      opacity: 0.25;
      width: 98%;
      border: 1px solid #707070;
      border-radius: 0 250px 0 0;
      @media (max-width: 768px) {
        width: 100%;
        border-radius: 0;
        border: none;
      }
    }
    .scroll-down {
      width: 98%;
      background: #EC5729;
      height: 150px;
      border-radius: 0 250px 0 0;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      @media (max-width: 768px) {
        display: none;
      }
      a {
        margin-left: auto;
        padding-right: 50px;
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 9;
        column-gap: 5px;
        @media (max-width: 768px) {
          margin-left: 0;
          padding-right: 0; 
          width: 100%;
          justify-content: center;
        }
        p {
          font-family: 'Optima';
          font-size: 18px;
          line-height: 1;
          color: #000000;
          width: 50%;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        img {
          width: 45px;
          height: 45px;
          object-fit: contain;
          animation: bounce2 3s ease infinite;
          @media (max-width: 768px) {
            width: 30px;
            height: 30px;
          }
        }
        @keyframes bounce2 {
	0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {transform: translateY(0);}
	20% {transform: translateY(-10px);}
	40% {transform: translateY(-8px);}
	60% {transform: translateY(-6px);}
	80% {transform: translateY(-4px);}
}
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0 32.5px;
      margin-bottom: 150px;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      // padding: 0 13vw;
      height: fit-content;
      padding: 0 32.5px;
      .right {
        order: -1;
        width: 100% !important;
        height: 800px;
        // padding-bottom: 380px;

        .about-us-lines {
          justify-content: center;
          font-weight: normal;
        }
      }

      .left {
        // padding-right: 30px;
        // padding-left: 10px;

        width: 100% !important;
      }
    }
    @media screen and (max-width: 540px) {
      .right {
        height: 80vh;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // row-gap: 10px;
      width: 40%;
      position: relative;
      font-family: "Montserrat";
      padding-left: 7vw;
      @media (max-width: 768px) {
        padding-left: 0;
      }
      h3 {
        text-transform: uppercase;
        color: #EC5729;
        margin: 0;
        margin-top: 60px;
        margin-bottom: 20px;
        font-family: "Optima";
        letter-spacing: 4px;
        font-size: $heading-size-m;
        font-weight: 100;
        @media screen and (max-width: 768px) {
          font-size: 18px;
          margin-top: 0;
          margin-bottom: 15px;
        }
        @media screen and (min-width: 1500px) {
          font-size: $heading-size-lg;
        }
      }

      strong {
        color: #ffffff;
        font-size: 28px;
        font-weight: 300;
        width: 100%;
        font-family: "Avenir";
        line-height: 40px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 35px;
        }
        // @media screen and (min-width: 1500px) {
        //   font-size: $paragraph-size-s;
        // }

        span {
          font-weight: 800;
        }
      }

      p.regular {
        color: #9fa4af;
        font-weight: 300;
        line-height: 1.7;
        font-size: $paragraph-size-m;
        @media screen and (max-width: 768px) {
          font-size: $paragraph-size-s;
        }
        @media screen and (min-width: 1500px) {
          font-size: $paragraph-size-xlg;
        }
      }

      p.italic {
        color: white;
        font-weight: 400;
        font-style: italic;
        line-height: 1.7;
        font-size: $paragraph-size-m;
        margin-top: 0;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }
      }
      .center-readmore {
        margin-top: 2px;
        font-size: 11px;
        color: white;
      }
      button {
        text-transform: uppercase;
        font-family: "Avenir";
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px 30px;
        background-color: transparent;
        border: 1px solid #EC5729;
        color: #fff;
        border-radius: 15px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          font-size: 10px;
          text-transform: uppercase;
          font-family: "Avenir";
          width: -moz-fit-content;
          width: fit-content;
          padding: 13px 40px;
          background-color: transparent;
          border: 1px solid #EC5729;
          color: #fff;
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      position: relative;
      // overflow: hidden;
    }
  }

  .ourservices {
    // margin-top: 100px;
    display: flex;

    // height: 100vh;
    min-height: 100vh;
    position: relative;

    .right {
      width: 50%;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 20px;
      width: 50%;

      h2 {
        font-size: 4.2vw;
        color: #222222;
        margin: 0 0 60px 0;
        position: relative;
      }

      .outlinetext {
        content: " ";
        font-size: 10vw;
        font-weight: 700;
        color: white;
        -webkit-text-stroke-width: 0.1vw;
        -webkit-text-stroke-color: #efefef;
        position: absolute;
        z-index: -1;
        left: -13%;
        top: -70%;
        white-space: nowrap;
        text-transform: uppercase;
      }

      p {
        text-align: justify;
        color: #707070;
        font-weight: 400;
        font-size: 1.3vw;
        margin: 0;
      }
    }
  }
}

// Page Margins
@media screen and (max-width: 1000px) {
  .aboutus {
    margin-bottom: 10vh;
  }
}

.services-separator {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 10px;
  min-height: 70vh;
  // overflow: hidden;
  position: relative;
  .sep-bg {
    position: absolute;
    opacity: 0.25;
    height: 70vh;
    object-fit: cover;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 120px 120px 0 0;
    @media (max-width: 768px ) {
      min-height: 90vh;
    }
  }
  .explore-btn {
    position: absolute;
    right: 10%;
    top: -20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      top: 20px;
    }
    img {
      width: 50px;
      height: 50px;
      background: #EC5729;
      padding: 25px;
      border-radius: 50%;
      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        display: none;
      }
    }
    h4 {
      width: 45%;
      color: white;
      font-size: 26px;
      text-align: right;
      margin-right: 70px;
      margin-top: 15px;
      font-weight: 300;
      font-family: 'Avenir';
      @media (max-width: 768px) {
        font-size: 16px;
        margin-right: 10px;
        margin-top: 30px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 0;
  }
}


@media (max-width: 1400px) {
  .line-anim-wrapper .aboutus .scroll-down {
    margin-top: 50px;
  }
}

@media (max-height: 720px) {
  .line-anim-wrapper .aboutus .left h3,
  .our-services h3,
  .clients-section h3,
  .team-section .sec-content h2,
  .our-projects-sec h3,
  .contact-us .top .contact-span {
    font-size: $heading-size-s !important;
  }
  .line-anim-wrapper .aboutus .left strong {
    font-size: $heading-size-xs !important;
  }
  .line-anim-wrapper .aboutus .left p.regular {
    font-size: $paragraph-size-s !important;
  }
  .about-us-lines .experience .experience-line h2 {
    font-size: 45px !important;
  }
  .about-us-lines .experience .experience-text span {
    font-size: $heading-size-xs !important;
  }
}