/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('../public/fonts/AvenirLTStd-Roman.otf') format('opentype');
} */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@font-face {
  font-family: "Avenir";
  src: local("Avenir LT Std 95 Black"), local("AvenirLTStd-Black"),
    url("../public/fonts/AvenirLTStd-Black.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Book";
  src: local("Avenir LT Std 45 Book"), local("AvenirLTStd-Book"),
    url("../public/fonts/AvenirLTStd-Book.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir LT Std 65 Medium"), local("AvenirLTStd-Medium"),
    url("../public/fonts/AvenirLTStd-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir LT Std 35 Light"), local("AvenirLTStd-Light"),
    url("../public/fonts/AvenirLTStd-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Roman";
  src: local("Avenir LT Std 55 Roman"), local("AvenirLTStd-Roman"),
    url("../public/fonts/AvenirLTStd-Roman.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Optima';
  font-style: normal;
  font-weight: normal;
  src: local('Optima'), url('../public/fonts/OPTIMA.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Optima Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Optima Italic'), url('../public/fonts/Optima_Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Optima Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Optima Medium'), url('../public/fonts/Optima Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Optima Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Optima Bold'), url('../public/fonts/OPTIMA_B.woff') format('woff');
  }

:root {
  --orange-main: #EC5729;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Poppins";
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
  background: #000;
}
a {
  text-decoration: none;
}

.dark-overlay::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 120%;
  background: #000;
  opacity: 0.7;
  pointer-events: none;
}


#root .app {
  overflow: hidden;
}

.swiper-wrapper {
  cursor: pointer;
}