.header {
  overflow-x: hidden;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style-type: none;
    text-align: center;
  }

  .section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #221f2e;
    color: #fff;
  }
  nav {
    width: 80vw;
    margin: 0 auto;
  }
  .nav-link {
    font-size: 60px;
    @media screen and (max-width: 600px) {
      font-size: 35px;
    }
    letter-spacing: 4px;
    font-family: "Avenir";
    font-weight: 700;
    color: #fff;
    // -webkit-text-stroke: 2px #fff;
    transition: 0.4s ease;
  }
  .nav-link:hover {
    color: #fff;
  }
  .img-wrapper {
    position: absolute;
    left: 11vw;
    top: -11vw;
    width: 22vw;
    height: 22vw;
    overflow: hidden;
    pointer-events: none;
    z-index: 10;
    border-radius: 50%;
    mix-blend-mode: difference;
    visibility: hidden;
    transform: scale(0.3);
  }
  .img-placeholder,
  .img-placeholder img {
    height: 100%;
    width: 100%;
  }
  .img-placeholder img {
    object-fit: cover;
    display: none;
  }
}
