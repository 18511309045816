@import "../../variables.scss";

.our-projects-sec {
    padding: 30px 0 0 7vw;
    position: relative;
    .explore-btn {
        position: absolute;
        right: 7vw;
        top: 70px;
        @media screen and (max-width: 768px) {
            display: none;
        }
        img {
            width: 50px;
            background: #EC5729;
            padding: 25px;
            border-radius: 50%;
            height: 50px;
        }
    }
    h3 {
        color: #EC5729;
        font-family: "Optima";
        letter-spacing: 4px;
        font-size: $heading-size-m;
        font-weight: 100;
        margin-bottom: 70px;
        position: relative;
        @media (max-width: 768px) {
            letter-spacing: 2px;
            font-size: 20px;
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 60%;
            height: 2px;
            background: linear-gradient(to right, #EC5729, transparent);
            left: 0;
            bottom: -25px;
        }
        
    }
    .subtitle {
        color: white;
        font-family: "Avenir";
        font-size: 26px;
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 35px;
        }
        span {
            font-weight: 600;
        }
    }
    p {
        color: #9FA4AF;
        width: 40%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .image-grid {
        display: flex;
        flex-direction: row;
        width: 90%;
        column-gap: 30px;
        .single-col {
            &:nth-child(1) {
                width: calc( 50% - 15px );
                display: flex;
                flex-direction: column;
                row-gap: 30px;
            }
            &:nth-child(2) {
                width: calc(50% - 15px);
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 30px;
                align-items: stretch;
                img {
                    &:nth-child(1) {
                        width: 100%;
                    }
                    &:nth-child(2) {
                        width: calc( 60% - 15px );
                    }
                    &:nth-child(3) {
                        width: calc( 40% - 15px );
                    }
                }
            }
        }
    }
}
    