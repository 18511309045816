@import "../../variables.scss";
.about-us-lines {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 5vw;
  font-weight: normal;

  @media screen and (max-width: 1400px) {
    gap: 5%;
  }
  @media (max-width: 540px) {
    gap: 0%;
  }

  @media screen and (max-width: 1000px) {
    margin-right: 0 !important;
  }
  @media screen and (max-width: 600px) {
    .experience {
      // height: 80% !important;
    }
  }
  @media screen and (max-width: 543px) {
    .experience {
      width: 110px !important;
      h2 {
        font-size: 30px !important;
      }
      span {
        font-size: 14px !important;
      }
    }
  }

  // margin-right: 10vh;
  z-index: 3;
  height: 80vh;

  .experience {
    width: 170px;
    height: 100%;
    // display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 60vh;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1300px) {
      height: 80%;
    }

    .experience-line {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      transform: translate(0, -80vh);

      h2 {
        // font-size: max(4vw, 60px);
        color: white;
        margin: 0;
        font-family: "Montserrat";   
        font-weight: 400; 
        font-size: 75px;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          font-size: 30px !important;
        }
      }

      .vertical-line {
        width: 2px;
        height: 100%;
        background: linear-gradient(transparent, #EC5729);
      }

      .line-circle {
        width: 12px;
        height: 12px;
        min-height: 12px;
        min-width: 12px;
        border-radius: 100%;
        background-color: white;
      }
    }

    .experience-text {
      display: flex;
      flex-direction: column;

      span {
        // transform: translate(0px, 350px);
        transform: translate(0px, -200px);
        opacity: 0;
        font-size: 28px;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        color: white;
        font-family: "Montserrat";
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
}
