@import "../../variables.scss";

.ourteampage {
    font-family: "Montserrat";
    .page-header {
        position: relative;
      .content {
        position: absolute;
        top: 30%;
        color: #fff;
        left: 15%;
        width: 50%;
        @media screen and (max-width: 1000px) {
          left: 10%;
          width: 80%;
        }
        .content-span {
          font-family: "Avenir";
          font-weight: 900;
          color: #EC5729;
          font-size: 20px;
          margin-bottom: 10px;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        .content-heading {
          font-family: "Montserrat";
          font-size: 50px;
          font-weight: 900;
          @media screen and (max-width: 768px) {
            font-size: 40px;
          }
        }
        .content-desc {
          font-family: "Avenir";
          font-weight: 300;
          font-size: 24px;
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 2;
          }
          strong {
            font-family: "Avenir";
            font-weight: 800;
          }
        }
      }
    }
    .our-improvement-section {
        color: white;
        display: flex;
        justify-content: center;
        column-gap: 50px;
        width: 80%;
        margin: 20px auto;
        .left,
        .right {
            width: calc(50% - 25px);
        }
        .right {
            .charts {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                column-gap: 20px;
                .chart {
                    width: calc(50% - 10px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 15px;
                    @media screen and (max-width: 600px) {
                        width: 80%;
                    }
                    .fieldname {
                        color: #f56901;
                        font-weight: 500;
                    }
                    .fielddesc {
                        margin: 0;
                        font-size: 14px;
                    }
                    .fieldname,
                    .fielddesc {
                        text-align: center;
                    }
                    img {
                        width: 100px;
                    }
                    .percentage {
                        font-weight: 300;
                        font-size: 24px;
                    }
                }
                @media screen and (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 40px;
                }
            }
            @media screen and (max-width: 900px) {
                width: 100%;
            }
        }
        .left {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            .sectiontitle,
            .sectioncontent {
                margin: 0;
            }
            .sectioncontent {
                font-weight: 300;
                line-height: 1.5;
            }
            .outlinedbtn {
                color: white;
                font-size: 12px;
                border: 1px solid #f56901;
                padding: 15px 30px;
                border-radius: 25px;
                width: fit-content;
            }
            @media screen and (max-width: 900px) {
                width: 100%;
            }
        }
        @media screen and (max-width: 900px) {
            width: 80%;
            margin: 20px auto;
            flex-direction: column;
            row-gap: 30px;
        }
    }
}