@import "../../variables.scss";

.partners-slider {
  position: relative;

  .nav {
    position: absolute;
    top: -60px;
    right: 0;
    width: 100%;
    left: 80%;
    display: none;
    .swiper-button-next {
      inset: 0;
      left: 50px;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
    .swiper-button-prev {
      inset: 0;
      &::after {
        font-size: 20px;
        color: white;
      }
    }
  }
}
