$drawer-shadow-size: 0.5;
$drawer-gap-horizontal: 8em;
$drawer-gap-vertical: 4em;
$drawer-animation-duration: 0.4s;
.logo {
  margin-left: 20px;
}
.main-header {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5705882353);
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  .drawer-close {
    float: right;

    &:before {
      display: block;
      content: "✕";
      font-size: 1.5em;
      width: 1em;
      height: 1em;
      padding: 0.5em;
      background: #aaa;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }

  .drawer {
    position: fixed;
    // top: $drawer-gap-vertical;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: #0a0a0a;
    transition: ease all $drawer-animation-duration;
    padding: 1em;
    box-sizing: border-box;
    overflow: auto;
    border-top: 1px solid #ccc;
    // height: calc(100vh-$drawer-gap-vertical);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &.closed {
      opacity: 0;
      box-shadow: none;
      pointer-events: none;
      animation: drawer-collapse-vertical $drawer-animation-duration;
      transform: translate(0, calc(100vh - #{$drawer-gap-vertical}));
    }

    &.open {
      opacity: 1;
      box-shadow: #{-1 * $drawer-shadow-size}em 0 #{2 * $drawer-shadow-size}em #{-1 *
        $drawer-shadow-size}em rgba(0, 0, 0, 0.5);
      animation: drawer-expand-horizontal $drawer-animation-duration;
    }
  }

  @media only screen and (min-width: 600px) {
    .drawer {
      width: 100%;
      border-left: 1px solid #ccc;
      border-top: none;
      height: auto;
      top: 0;

      &.closed {
        animation: drawer-collapse-horizontal $drawer-animation-duration;
      }

      &.open {
        box-shadow: 0 #{-1 * $drawer-shadow-size}em #{2 * $drawer-shadow-size}em
          #{-1 * $drawer-shadow-size}em rgba(0, 0, 0, 0.5);
        animation: drawer-expand-horizontal $drawer-animation-duration;
      }
    }
  }

  @keyframes drawer-expand-horizontal {
    0% {
      transform: translate(calc(100vw - #{$drawer-gap-horizontal}));
    }

    50% {
      transform: translate(-1em);
    }

    100% {
      transform: translate(0);
    }
  }

  @keyframes drawer-collapse-horizontal {
    0% {
      transform: translate(0);
    }

    50% {
      transform: translate(-1.5em) scale(0.98);
      filter: blur(2px);
    }

    100% {
      transform: translate(calc(100vw - #{$drawer-gap-horizontal})) scale(0.5);
      filter: blur(5px);
    }
  }

  @keyframes drawer-expand-vertical {
    0% {
      transform: translate(0, calc(100vh - #{$drawer-gap-vertical}));
    }

    50% {
      transform: translate(0, -1em);
    }

    100% {
      transform: translate(0);
    }
  }

  @keyframes drawer-collapse-vertical {
    0% {
      transform: translate(0);
    }

    50% {
      transform: translate(0, -1em) scale(0.98);
      filter: blur(2px);
    }

    100% {
      transform: translate(0, calc(100vh - #{$drawer-gap-vertical})) scale(0.5);
      filter: blur(5px);
    }
  }
  // vars
  $color: #000; // icon color
  $blue: rgba(255, 255, 255, 0); // background color
  $animation: 0.6s; // animation speed
  $scale: 1; // icon scale 68/68 default

  .hamburglar {
    z-index: 101;
    // transform: scale($scale);
    transform: scale(0.7);
    // margin: 40px auto;
    position: relative;
    display: block;
    width: 68px;
    height: 68px;
    background: $blue;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
  }

  // transition mask
  .path-burger {
    position: absolute;
    top: 0;
    left: 0;
    height: 68px;
    width: 68px;
    // two masks because... browser support.
    mask: url(#mask);
    -webkit-mask-box-image: url(https://raygun.io/upload/mask.svg);
  }

  .animate-path {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
  }

  // what this does is create a small square that I then rotate behind an svg mask, giving the apparence of the line animating
  .path-rotation {
    height: 34px;
    width: 34px;
    margin: 34px 34px 0 0;
    transform: rotate(0deg);
    transform-origin: 100% 0;
    &:before {
      content: "";
      display: block;
      width: 30px;
      height: 34px;
      margin: 0 4px 0 0;
      background: #fff;
    }
  }

  // box rotation animation
  @keyframes rotate-out {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-in {
    0% {
      transform: rotate(360deg);
    }
    40% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  // offset moves
  // dasharray is the dash size
  // need to be able to control dash space size.

  .hamburglar.is-open {
    .path {
      animation: dash-in $animation linear normal;
      animation-fill-mode: forwards;
    }
    .animate-path {
      animation: rotate-in $animation linear normal;
      animation-fill-mode: forwards;
    }
    .burger-filling,
    .burger-bun-top,
    .burger-bun-bot {
      background-color: #fff;
    }
  }

  .hamburglar.is-closed {
    .path {
      animation: dash-out $animation linear normal;
      animation-fill-mode: forwards;
    }
    .animate-path {
      animation: rotate-out $animation linear normal;
      animation-fill-mode: forwards;
    }
  }

  .path {
    stroke-dasharray: 240;
    stroke-dashoffset: 240;
    stroke-linejoin: round;
  }

  @keyframes dash-in {
    0% {
      stroke-dashoffset: 240;
    }
    40% {
      stroke-dashoffset: 240;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dash-out {
    0% {
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dashoffset: 240;
    }
    100% {
      stroke-dashoffset: 240;
    }
  }

  // All good burgers need filling!

  .burger-icon {
    position: absolute;
    padding: 20px 16px;
    height: 68px;
    width: 68px;
  }

  .burger-container {
    position: relative;
    height: 28px;
    width: 36px;
  }

  .burger-bun-top,
  .burger-bun-bot,
  .burger-filling {
    position: absolute;
    display: block;
    height: 3px;
    width: 36px;
    border-radius: 2px;
    background: #fff;
  }

  .burger-bun-top {
    top: 0;
    transform-origin: 34px 2px;
  }

  .burger-bun-bot {
    bottom: 0;
    transform-origin: 34px 2px;
  }
  //.burger-filling {
  //  @include transition(all,($animation/2.5),ease-in-//out);
  //}
  // relative parent is the button
  .burger-filling {
    top: 12px;
  }

  // burger ring container
  .burger-ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
  }

  .svg-ring {
    width: 68px;
    height: 68px;
  }

  // bun animations
  .hamburglar.is-open {
    .burger-bun-top {
      animation: bun-top-out $animation linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-bot {
      animation: bun-bot-out $animation linear normal;
      animation-fill-mode: forwards;
    }
  }
  .hamburglar.is-closed {
    .burger-bun-top {
      animation: bun-top-in $animation linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-bot {
      animation: bun-bot-in $animation linear normal;
      animation-fill-mode: forwards;
    }
  }

  @keyframes bun-top-out {
    0% {
      left: 0;
      top: 0;
      transform: rotate(0deg);
    }
    20% {
      left: 0;
      top: 0;
      transform: rotate(15deg);
    }
    80% {
      left: -5px;
      top: 0;
      transform: rotate(-60deg);
    }
    100% {
      left: -5px;
      top: 1px;
      transform: rotate(-45deg);
    }
  }

  @keyframes bun-bot-out {
    0% {
      left: 0;
      transform: rotate(0deg);
    }
    20% {
      left: 0;
      transform: rotate(-15deg);
    }
    80% {
      left: -5px;
      transform: rotate(60deg);
    }
    100% {
      left: -5px;
      transform: rotate(45deg);
    }
  }

  @keyframes bun-top-in {
    0% {
      left: -5px;
      bot: 0;
      transform: rotate(-45deg);
    }
    20% {
      left: -5px;
      bot: 0;
      transform: rotate(-60deg);
    }
    80% {
      left: 0;
      bot: 0;
      transform: rotate(15deg);
    }
    100% {
      left: 0;
      bot: 1px;
      transform: rotate(0deg);
    }
  }

  @keyframes bun-bot-in {
    0% {
      left: -5px;
      transform: rotate(45deg);
    }
    20% {
      left: -5px;
      bot: 0;
      transform: rotate(60deg);
    }
    80% {
      left: 0;
      bot: 0;
      transform: rotate(-15deg);
    }
    100% {
      left: 0;
      transform: rotate(0deg);
    }
  }

  // burger filling
  .hamburglar.is-open {
    .burger-filling {
      animation: burger-fill-out $animation linear normal;
      animation-fill-mode: forwards;
    }
  }

  .hamburglar.is-closed {
    .burger-filling {
      animation: burger-fill-in $animation linear normal;
      animation-fill-mode: forwards;
    }
  }

  @keyframes burger-fill-in {
    0% {
      width: 0;
      left: 36px;
    }
    40% {
      width: 0;
      left: 40px;
    }
    80% {
      width: 36px;
      left: -6px;
    }
    100% {
      width: 36px;
      left: 0px;
    }
  }

  @keyframes burger-fill-out {
    0% {
      width: 36px;
      left: 0px;
    }
    20% {
      width: 42px;
      left: -6px;
    }

    40% {
      width: 0;
      left: 40px;
    }

    100% {
      width: 0;
      left: 36px;
    }
  }
}
